import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { useAuth0 } from "@auth0/auth0-react";

const userKey = sessionStorage.getItem('userKey');
console.log('userKey:', userKey);


const user = {
  name: 'Don Acker',
  email: 'dacker@turn10.com',
  imageUrl:
    'https://media.licdn.com/dms/image/D4E03AQEfFSxQZntSQQ/profile-displayphoto-shrink_400_400/0/1675106836011?e=1687996800&v=beta&t=GS2Ca9qB3QnV_D7TzIdB_cP1q8x8SLxL7TRRoLw8VoA',
}
const adminNavigation = [
  { name: 'Database', href: '/db' },
  { name: 'Company', href: '/company' },
  { name: 'Users', href: '/users' },
]
const appNavigation = [
  { name: 'Scrapers', href: '/scraper' },
  { name: 'Pre-Processors', href: '/processor' },
  { name: 'Programs', href: '/program' },
]
const navigation = [
  { name: 'OctoHub', href: '/', current: true },
  { name: 'Applications', href: '#', dropdown: true, submenuItems: appNavigation, current: false },
  { name: 'APIs', href: '#', current: false },
  { name: 'Admin', href: '#', dropdown: true, submenuItems: adminNavigation, current: false},
]

const DropdownMenu = ({ title, menuItems, setPageTitle }) => (
  <Menu as="div" className="relative inline-block text-left">
    {({ open }) => (
      <>
        <div>
          <Menu.Button
            className={classNames(
              open
                ? 'bg-neutral-900 text-white'
                : 'text-neutral-300 hover:bg-neutral-700 hover:text-white',
              'rounded-md px-3 py-2 text-sm font-medium'
            )}
          >
            {title}
          </Menu.Button>
        </div>
        <Transition
          show={open}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            static
            className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div className="py-1">
              {menuItems.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      to={item.href}
                      className={classNames(
                        active ? 'bg-neutral-100 text-neutral-900' : 'text-neutral-700',
                        'block px-4 py-2 text-sm'
                      )}
                      onClick={() => setPageTitle(item.name)}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </>
    )}
  </Menu>
);
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navigation({ children }) {
  const [pageTitle, setPageTitle] = useState('OctoHub');
  const { logout } = useAuth0();
  const userNavigation = [
  { name: 'Your Profile', href: '/profile/user' },
  { name: 'Company Profile', href: '/profile/company' },
  { name: 'Sign out', action: () => logout({ returnTo: `${window.location.origin}/logout` }) },
  ]
  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-neutral-800">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-8 w-8"
                        src="/img/OctoHub_Icon.png?color=sky&shade=500"
                        alt="Turn10 Innovations"
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                           {navigation.map((item) => (
                            item.dropdown ? (
                              <DropdownMenu
                                title={item.name}
                                menuItems={item.submenuItems}
                                setPageTitle={setPageTitle}
                              />
                            ) : (
                              <Link
                                key={item.name}
                                to={item.href}
                                className={classNames(
                                  item.current
                                    ? 'bg-neutral-900 text-white'
                                    : 'text-neutral-300 hover:bg-neutral-700 hover:text-white',
                                  'rounded-md px-3 py-2 text-sm font-medium'
                                )}
                                onClick={() => setPageTitle(item.name)}
                                aria-current={item.current ? 'page' : undefined}
                              >
                                {item.name}
                              </Link>
                            )
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <button
                        type="button"
                        className="rounded-full bg-neutral-800 p-1 text-neutral-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-neutral-800"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex max-w-xs items-center rounded-full bg-neutral-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-neutral-800">
                            <span className="sr-only">Open user menu</span>
                            <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                    item.action
                                    ? <button
                                        onClick={item.action}
                                        className={classNames(
                                            active ? 'bg-neutral-100' : '',
                                            'block px-4 py-2 text-sm text-neutral-700'
                                        )}
                                        >
                                        {item.name}
                                        </button>
                                    : <Link
                                    key={item.name}
                                    to={item.href}
                                    className={classNames(
                                      active ? 'bg-neutral-100' : '',
                                      'block px-4 py-2 text-sm text-neutral-700'
                                    )}
                                    onClick={() => setPageTitle(item.name)}
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-neutral-800 p-2 text-neutral-400 hover:bg-neutral-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-neutral-800">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                      item.action
                      ? <button
                        onClick={item.action}
                        className="block rounded-md px-3 py-2 text-base font-medium text-neutral-300 hover:bg-neutral-700 hover:text-white"
                        >
                        {item.name}
                        </button>
                    : <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-neutral-900 text-white' : 'text-neutral-300 hover:bg-neutral-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-neutral-700 pb-3 pt-4">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">{user.name}</div>
                      <div className="text-sm font-medium leading-none text-neutral-400">{user.email}</div>
                    </div>
                    <button
                      type="button"
                      className="ml-auto flex-shrink-0 rounded-full bg-neutral-800 p-1 text-neutral-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-neutral-800"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-neutral-400 hover:bg-neutral-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-neutral-900">{pageTitle}</h1>
          </div>
        </header>
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            { children }
          </div>
        </main>
      </div>
    </>
  )
}

/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import React, { useState } from 'react'
import FormCompanyAdd from "./FormCompanyAdd";
const tabs = [
  { name: 'Reports', id: 'reports' },
  { name: 'Activity', id: 'activity' },
  { name: 'Settings', id: 'settings' },
  { name: 'Collaborators', id: 'collaborators' },
  { name: 'Administration', id: 'administration' },
];

export default function MenuScraperUnanet() {
    const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'reports':
        return <FormCompanyAdd />;
      case 'activity':
        return <div>Activity content</div>;
      case 'settings':
        return <div>Settings content</div>;
      case 'collaborators':
        return <div>Collaborators content</div>;
      case 'administration':
        return <FormCompanyAdd />;
      default:
        return null;
    }
  };

  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:px-6">
                <div className="px-4 py-6 sm:px-6 lg:px-8">
                  <div className="mx-auto max-w-7xl">
                    <div className="sm:hidden">
                      <label htmlFor="tabs" className="sr-only">
                        Select a tab
                      </label>
                      {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                      <select
                        id="tabs"
                        name="tabs"
                        className="block w-full rounded-md border-none bg-white/5 py-2 pl-3 pr-10 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm"
                        onChange={handleTabChange}
                      >
                        {tabs.map((tab) => (
                          <option key={tab.name}>{tab.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="hidden sm:block">
                      <nav className="flex border-b border-white/10 py-4">
                        <ul
                          className="flex min-w-full flex-none gap-x-6 px-2 text-sm font-semibold leading-6 text-gray-400"
                        >
                          {tabs.map((tab) => (
                            <li key={tab.id}>
                              <button
                                  onClick={() => handleTabChange(tab.id)}
                                  className={activeTab === tab.id ? 'text-indigo-400' : ''}
                              >
                                {tab.name}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>

      </div>
      <div className="bg-gray-50 px-4 py-6 pb-2 sm:p-6">{renderContent()}</div>
      <div className="h-12"></div>
    </div>
    );
}

import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

export default function ListProcessor() {
    const { user, getAccessTokenSilently } = useAuth0();

  const [processors, setProcessors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: `https://i25ghht0f6.execute-api.us-east-1.amazonaws.com`,
          scope: "read:current_user",
        });

        const userID = user.sub.split('|')[1];

        const response = await axios.post('https://i25ghht0f6.execute-api.us-east-1.amazonaws.com/api/processor/list', {
          userID: userID
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache',
          }
        });

        // Set the processors state to the data returned by the API
        setProcessors(response.data);

      } catch (error) {
        console.error('Error fetching processor data:', error);
      }
    };

    fetchData();
  }, [user, getAccessTokenSilently]);


  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Pre-Processors</h1>
          <p className="mt-2 text-sm text-gray-700">
            Select the processor you would like to envoke by clicking Run.
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Preprocessor
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Description
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Run</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {processors.map((processor) => (
                    <tr key={processor.key}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {processor.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{processor.description}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <Link to= {processor.route} className="text-indigo-600 hover:text-indigo-900">
                          Run<span className="sr-only">, {processor.name}</span>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
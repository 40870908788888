import './App.css';
import { BrowserRouter, Route, Routes} from 'react-router-dom';

import Navigation from './components/Navigation/Navigation';

import OctoScrapeMain from './components/OctoScrapeMain';

import ListDbConfig from './components/ListDbConfig';
import FormDbConfigEdit from './components/FormDbConfigEdit';
import FormDbConfigAdd from './components/FormDbConfigAdd';

import ListUser from './components/ListUser';
import FormUserEdit from './components/FormUserEdit';
import FormUserAdd from './components/FormUserAdd';

import ListCompany from './components/ListCompany';
import FormCompanyEdit from './components/FormCompanyEdit';
import FormCompanyAdd from './components/FormCompanyAdd';

import ListCompanyProfile from "./components/ListCompanyProfile";
import ListUserProfile from "./components/ListUserProfile";

import MenuScraperUnanet from './components/MenuScraperUnanet';

import ListProcessor from './components/ListProcessor';

import LogoutPage from "./components/LogoutPage";

import { useAuth0 } from "@auth0/auth0-react";

function ProtectedRoute({ children }) {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return <div>Redirecting you to login...</div>;
  }

  return children;
}


function App() {

  return (
    <BrowserRouter>
      <Navigation />
      <Routes>
        <Route path="/" element={<ProtectedRoute><OctoScrapeMain /></ProtectedRoute>} />
        <Route path="/db" element={<ProtectedRoute><ListDbConfig /></ProtectedRoute>} />
        <Route path="/db/edit" element={<ProtectedRoute><FormDbConfigEdit /></ProtectedRoute>} />
        <Route path="/db/new" element={<ProtectedRoute><FormDbConfigAdd /></ProtectedRoute>} />
        <Route path="/company" element={<ProtectedRoute><ListCompany /></ProtectedRoute>} />
        <Route path="/company/edit/:editCompanyKey" element={<ProtectedRoute><FormCompanyEdit /></ProtectedRoute>} />
        <Route path="/company/new" element={<ProtectedRoute><FormCompanyAdd /></ProtectedRoute>} />
        <Route path="/users" element={<ProtectedRoute><ListUser /></ProtectedRoute>} />
        <Route path="/users/new" element={<ProtectedRoute><FormUserAdd /></ProtectedRoute>} />
        <Route path="/users/edit/:editUserKey" element={<ProtectedRoute><FormUserEdit /></ProtectedRoute>} />
        <Route path="/scraper" element={<ProtectedRoute><MenuScraperUnanet /></ProtectedRoute>} />
        <Route path="/processor" element={<ProtectedRoute><ListProcessor /></ProtectedRoute>} />
        <Route path="/logout" element={<LogoutPage />} />
        {
            /* Route path for Profile Dropdown Menu} */
        }
        <Route path="/profile/company" element={<ProtectedRoute><ListCompanyProfile /></ProtectedRoute>} />
        <Route path="/profile/user" element={<ProtectedRoute><ListUserProfile /></ProtectedRoute>} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;

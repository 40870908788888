export default function OctoScrapeMain() {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
      <div className="mx-auto max-w-3xl flex-auto">
          <img src="/img/OctoHubFull.png" alt="OctoHubLogo" className="md_bg-center"/>
          {/* Content goes here */}
      </div>
    </div>
  )
}

const people = [
  {
    name: 'Don Acker',
    email: 'dacker@turn10llc.com',
    role: 'Admnistrator',
    imageUrl:
      'https://media.licdn.com/dms/image/D4E03AQEfFSxQZntSQQ/profile-displayphoto-shrink_400_400/0/1675106836011?e=1687996800&v=beta&t=GS2Ca9qB3QnV_D7TzIdB_cP1q8x8SLxL7TRRoLw8VoA',
    lastSeen: '1m ago',
    lastSeenDateTime: '2023-01-23T13:23Z',
  }
]

export default function ListUserProfile() {
  return (
    <ul className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      {people.map((person) => (
        <li key={person.email} className="flex justify-between gap-x-6 py-5">
          <div className="flex gap-x-4">
            <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.imageUrl} alt="" />
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-900">{person.name}</p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.email}</p>
            </div>
          </div>
          <div className="hidden sm:flex sm:flex-col sm:items-end">
            <p className="text-sm leading-6 text-gray-900">{person.role}</p>
            {person.lastSeen ? (
              <p className="mt-1 text-xs leading-5 text-gray-500">
                Last seen <time dateTime={person.lastSeenDateTime}>{person.lastSeen}</time>
              </p>
            ) : (
              <div className="mt-1 flex items-center gap-x-1.5">
                <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                  <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                </div>
                <p className="text-xs leading-5 text-gray-500">Online</p>
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
  )
}

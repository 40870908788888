import React from 'react';

const LogoutPage = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <h1 className="text-2xl md:text-4xl font-bold text-gray-900 mb-6">You have been logged out</h1>
            <p className="text-gray-700 mb-12 text-center max-w-sm mx-auto">Thank you for visiting. We hope to see you again soon.</p>
            <a href="/" className="px-8 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                Log in again
            </a>
        </div>
    )
}

export default LogoutPage;

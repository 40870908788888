import { createContext, useContext } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const useUserData = () => {
  return useContext(UserContext);
};

export const fetchUserData = async () => {
  const userKey = sessionStorage.getItem('userKey');

  try {
    const response = await axios.post('https://rpqqmrsufk.execute-api.us-east-1.amazonaws.com/Prod/api/user', { userKey });
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
};